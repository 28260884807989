<template>
  <b-modal id="SponsorInfo" scrollable size="lg" centered hide-footer>
    <template #modal-title>
      <h3>
        <img src="@/assets/images/sponsors.svg" class="icon-lg" />
        {{ $t("sponsors.data") }}
      </h3>
    </template>
    <div class="row">
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="sponsorData.sponsorNameAr"
        :title="$t('sponsors.nameAr')"
        :imgName="'sponsors.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="sponsorData.sponsorNameEn"
        :title="$t('sponsors.nameEn')"
        :imgName="'sponsors.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="sponsorData.sponsorPreferedTypeNameCurrent"
        :title="$t('preferedType')"
        :imgName="'favorite.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="sponsorData.sponsorDescriptionAr"
        :title="$t('sponsors.descriptionAr')"
        :imgName="'sponsors.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="sponsorData.sponsorDescriptionEn"
        :title="$t('sponsors.descriptionEn')"
        :imgName="'sponsors.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="sponsorData.fullCode"
        :title="$t('code')"
        :imgName="'number.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-12'"
        :value="sponsorData.sponsorNotes"
        :title="$t('notes')"
        :imgName="'notes.svg'"
      />
    </div>
  </b-modal>
</template>

<script>
import DataLabelGroup from "@/components/general/DataLabelGroup.vue";
import { timeToLang } from "@/utils/functions";

export default {
  name: "SponsorInfo",
  components: {
    DataLabelGroup,
  },
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
    };
  },
  props: ["sponsorData"],
  methods: {
    timeToLang(time) {
      return timeToLang(time, this.language);
    },
    setDataMultiLang(lang, dataAr, dataEn) {
      if (lang == "en") return dataEn;
      else return dataAr;
    },
  },
};
</script>

<style scoped lang="scss"></style>
