import PaginationData from "@/models/general/PaginationData";
import Sponsor from "./Sponsor";
import SponsorsFilter from "./SponsorsFilter";

export default class Sponsors {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.status = 0;
    this.msg = "";
    this.pagination = new PaginationData();
    this.sponsorsData = [];
    this.sponsor = new Sponsor();
    this.filterData = new SponsorsFilter();
  }
  fillData(data) {
    this.status = data.status;
    this.msg = data.msg;
    this.pagination.fillData(data.pagination);
    this.sponsorsData = data.sponsorsData;
  }
}
