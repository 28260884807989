var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flip-card col-12 col-sm-6 col-md-4 col-lg-3"},[_c('div',{staticClass:"flip-card-inner"},[_c('div',{staticClass:"flip-card-front"},[_c('img',{attrs:{"src":_vm.imgSrc,"onerror":("this.src='" + _vm.defaultImg + "'"),"alt":"Image"}}),_c('div',{staticClass:"flip-card-title"},[_c('h4',{staticClass:"cut-1line",attrs:{"title":_vm.sponsor.sponsorNameCurrent}},[_vm._v(" "+_vm._s(_vm.sponsor.sponsorNameCurrent)+" ")])])]),_c('div',{staticClass:"flip-card-back"},[_c('div',{staticClass:"flip-card-title-top"},[_c('h6',{staticClass:"cut-1line"},[_vm._v(_vm._s(_vm.sponsor.fullCode))])]),_c('div',{staticClass:"flip-card-back-icons"},[_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal.SponsorInfo",modifiers:{"SponsorInfo":true}}],staticClass:"btn p-0",attrs:{"title":_vm.$t('info')},on:{"click":_vm.setSponsorData}},[_c('img',{staticClass:"icon-lg",attrs:{"src":require("@/assets/images/info.svg")}})]),(
            _vm.checkPrivilege(_vm.usersPrivilegeData.usersPrivilegeSponsors.update)
          )?_c('router-link',{attrs:{"to":{
            name: 'SponsorEdit',
            params: {
              sponsorToken: _vm.sponsor.sponsorToken,
            },
          },"title":_vm.$t('edit')}},[_c('img',{staticClass:"icon-lg",attrs:{"src":require("@/assets/images/pencil.svg")}})]):_vm._e(),(
            _vm.checkPrivilege(_vm.usersPrivilegeData.usersPrivilegeSponsors.archive)
          )?_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal.SponsorDelete",modifiers:{"SponsorDelete":true}}],staticClass:"btn p-0",attrs:{"title":_vm.$t('delete')},on:{"click":_vm.setSponsorData}},[_c('img',{staticClass:"icon-lg",attrs:{"src":require("@/assets/images/trash.svg")}})]):_vm._e()],1),_c('div',{staticClass:"flip-card-title"},[_c('h4',{staticClass:"cut-1line",attrs:{"title":_vm.sponsor.sponsorNameCurrent}},[_vm._v(" "+_vm._s(_vm.sponsor.sponsorNameCurrent)+" ")])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }