import { render, staticRenderFns } from "./SponsorInfo.vue?vue&type=template&id=30ec2bec&scoped=true&"
import script from "./SponsorInfo.vue?vue&type=script&lang=js&"
export * from "./SponsorInfo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30ec2bec",
  null
  
)

export default component.exports