<template>
  <div class="flip-card col-12 col-sm-6 col-md-4 col-lg-3">
    <div class="flip-card-inner">
      <div class="flip-card-front">
        <img :src="imgSrc" :onerror="`this.src='${defaultImg}'`" alt="Image" />
        <div class="flip-card-title">
          <h4 class="cut-1line" :title="sponsor.sponsorNameCurrent">
            {{ sponsor.sponsorNameCurrent }}
          </h4>
        </div>
      </div>
      <div class="flip-card-back">
        <div class="flip-card-title-top">
          <h6 class="cut-1line">{{ sponsor.fullCode }}</h6>
        </div>
        <div class="flip-card-back-icons">
          <button
            v-b-modal.SponsorInfo
            class="btn p-0"
            :title="$t('info')"
            @click="setSponsorData"
          >
            <img src="@/assets/images/info.svg" class="icon-lg" />
          </button>

          <router-link
            v-if="
              checkPrivilege(usersPrivilegeData.usersPrivilegeSponsors.update)
            "
            :to="{
              name: 'SponsorEdit',
              params: {
                sponsorToken: sponsor.sponsorToken,
              },
            }"
            :title="$t('edit')"
          >
            <img src="@/assets/images/pencil.svg" class="icon-lg" />
          </router-link>
          <button
            v-if="
              checkPrivilege(usersPrivilegeData.usersPrivilegeSponsors.archive)
            "
            v-b-modal.SponsorDelete
            class="btn p-0"
            :title="$t('delete')"
            @click="setSponsorData"
          >
            <img src="@/assets/images/trash.svg" class="icon-lg" />
          </button>
        </div>
        <div class="flip-card-title">
          <h4 class="cut-1line" :title="sponsor.sponsorNameCurrent">
            {{ sponsor.sponsorNameCurrent }}
          </h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { checkPrivilege } from "@/utils/functions";
import { BASE_URL } from "@/utils/constants";
import DEFAULT_IMG from "@/assets/images/sponsors.svg";

export default {
  name: "SponsorCard",
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
      defaultImg: DEFAULT_IMG,
    };
  },
  props: ["sponsor"],
  methods: {
    setSponsorData() {
      this.$emit("setSponsorData", this.sponsor);
    },
    checkPrivilege,
  },
  computed: {
    ...mapGetters(["usersPrivilegeData"]),
    imgSrc: function () {
      return BASE_URL + this.sponsor.sponsorImagePath;
    },
  },
};
</script>

<style lang="scss"></style>
