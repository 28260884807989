<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <div v-if="sponsors.sponsorsData">
      <div class="row">
        <SponsorCard
          v-for="(sponsor, index) in sponsors.sponsorsData"
          :key="index"
          :sponsor="sponsor"
          v-on:setSponsorData="sponsors.sponsor.fillData($event)"
        />
      </div>

      <SponsorInfo :sponsorData="sponsors.sponsor" />
      <SponsorDelete
        :sponsorData="sponsors.sponsor"
        v-on:refresh="getAllSponsors()"
      />

      <Pagination
        v-if="!isLoading"
        :paginationData="sponsors.pagination"
        v-on:changePage="changePage"
      />
    </div>

    <SponsorFloatBtns
      :theFilterData="sponsors.filterData"
      v-on:search="search($event)"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import createToastMixin from "@/utils/create-toast-mixin";
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { STATUS } from "@/utils/constants";
import SponsorCard from "@/components/sponsors/SponsorCard.vue";
import SponsorInfo from "@/components/sponsors/SponsorInfo.vue";
import SponsorDelete from "@/components/sponsors/SponsorDelete.vue";
import SponsorFloatBtns from "@/components/sponsors/SponsorFloatBtns.vue";
import Pagination from "@/components/general/Pagination.vue";
import Sponsors from "@/models/sponsors/Sponsors";

export default {
  mixins: [createToastMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    SponsorCard,
    SponsorInfo,
    SponsorDelete,
    SponsorFloatBtns,
    Pagination,
  },
  computed: {
    ...mapGetters(["userAuthorizeToken"]),
  },
  data() {
    return {
      isLoading: false,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      sponsors: new Sponsors(),
    };
  },
  methods: {
    changePage(page) {
      this.sponsors.pagination.selfPage = page;
      this.getAllSponsors();
    },
    search(data) {
      this.sponsors.filterData.fillData(data);
      this.sponsors.sponsorsData = [];
      this.getAllSponsors();
    },
    async getAllSponsors() {
      this.isLoading = true;
      try {
        const response = await this.sponsors.sponsor.getAllSponsors(
          this.language,
          this.userAuthorizeToken,
          this.sponsors.pagination,
          this.sponsors.filterData
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.exceptionMsg = null;

          this.sponsors.fillData(response.data);
        } else if (response.data.status == STATUS.NO_CONTENT) {
          this.sponsors.sponsorsData = null;
          this.exceptionMsg = response.data.msg;
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.sponsors.sponsorsData = null;
          this.$store.dispatch("logoutUser");
        } else {
          this.sponsors.sponsorsData = null;
          this.exceptionMsg = response.data.msg;
        }
      } catch (error) {
        this.sponsors.sponsorsData = null;
        this.exceptionMsg = this.$t("errorCatch");
      }
      this.isLoading = false;
    },
  },
  async created() {
    this.getAllSponsors();
  },
};
</script>
